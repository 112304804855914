import React, { useState, useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import iconPlus from "../../images/softwere-development/iconPlus.svg"
import iconMinus from "../../images/softwere-development/iconminus.svg"
import * as styles from "./Process.module.scss"
import "./Process.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  const isFirstStep = eventKey === "ek-0"

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div
        className={`${styles.centerWrapper} d-flex justify-content-between align-items-center`}
      >
        <div
          className={
            isFirstStep && isCurrentEventKey
              ? styles.quesColorSelected
              : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button" className={styles.faqButton}>
          {isFirstStep && isCurrentEventKey ? (
            <img
              src={iconMinus}
              className={styles.quesColorSelected}
              decoding="async"
              loading="lazy"
              alt=""
            />
          ) : (
            <img
              src={isCurrentEventKey ? iconMinus : iconPlus}
              className={styles.quesColor}
              decoding="async"
              loading="lazy"
              alt=""
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Process = ({ strapiData, webSteps }) => {
  const [imageInd, setImageInd] = useState(0)
  const [current, setCurrent] = useState(0)

  const handleStepClick = index => {
    if (index === imageInd) {
      setImageInd(0)
      setCurrent(0)
    } else {
      setImageInd(index)
      setCurrent(index)
    }
  }
  return (
    <div className={styles.processSoftware}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={`${styles.description} ${webSteps ? styles.webDesc : ""}`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
            className={styles.leftColprocess}
          >
            <div className=" softwareEasySteps faqsAccordian d-flex">
              <Accordion defaultActiveKey={`ek-${imageInd}`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card
                    key={i}
                    //border="light"
                    className={`${"custom-border"}`}
                    style={{ overflow: "visible" }}
                    onClick={() => handleStepClick(i)}
                  >
                    <ContextAwareToggle
                      eventKey={`ek-${i}`}
                      ind={`ek-${imageInd}`}
                    >
                      <div className={` ${styles.faqsNum}`}>
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                        <span className={styles.serial2}>Step 0{+(i + 1)}</span>
                        <span className={styles.serial}>Step 0{+(i + 1)}</span>

                        {/* <div className={styles.plusMinusImages}>
                          <img src={iconPlus} alt="" />
                          <img src={iconMinus} alt="" />
                        </div> */}
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse
                      className={`${imageInd === i && "show"}`}
                    >
                      <Card.Body className={styles.textColor}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.subTitle,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.crossImg}>
              <img
                src={
                  strapiData?.cards[imageInd]?.image1 &&
                  strapiData?.cards[imageInd]?.image1[0]?.localFile?.publicURL
                }
                decoding="async"
                loading="lazy"
                // alt={strapiData?.cards[imageInd]?.image1[0]?.alternativeText}
                alt="Software Development"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Process
