// extracted by mini-css-extract-plugin
export var centerWrapper = "Process-module--centerWrapper--3ce4b";
export var crossImg = "Process-module--crossImg--0029a";
export var description = "Process-module--description--a54e0";
export var description2 = "Process-module--description2--589c5";
export var faqButton = "Process-module--faqButton--5eb90";
export var faqsNum = "Process-module--faqsNum--a6a5b";
export var faqsSub = "Process-module--faqsSub--22672";
export var heading = "Process-module--heading--56fd7";
export var headr = "Process-module--headr--44c25";
export var leftColprocess = "Process-module--leftColprocess--cbf55";
export var processSoftware = "Process-module--processSoftware--4bcfb";
export var quesColor = "Process-module--quesColor--d9525";
export var quesColorSelected = "Process-module--quesColorSelected--b49d2";
export var reverse = "Process-module--reverse--c3940";
export var serial = "Process-module--serial--e4575";
export var serial2 = "Process-module--serial2--ed3ab";
export var textColor = "Process-module--textColor--27ec2";
export var webDesc = "Process-module--webDesc--7f8bb";